import React from "react"

import { SegmentEvent } from "../../modules/segment"
import { ExploreButton } from "./EnterMetaverseButton.styled"
import { EnterMetaverseButtonProps } from "./EnterMetaverseButton.types"

const EnterMetaverseButton = React.memo((props: EnterMetaverseButtonProps) => {
  const { href, onClick, label } = props
  return (
    <ExploreButton
      variant="contained"
      data-event={SegmentEvent.GoToExplorer}
      data-place="hero"
      href={href}
      onClick={onClick}
    >
      {label}
    </ExploreButton>
  )
})

export { EnterMetaverseButton }
