import React, { useRef, useState } from "react"

import { useInView } from "react-intersection-observer"

import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded"
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded"
import {
  Autoplay,
  EffectCoverflow,
  Lazy,
  Navigation,
  Swiper as SwiperClass,
} from "swiper"

import { ContentfulWhatsHotListProps } from "../../../hooks/useLandingContentfulQuery"
import {
  NavigationButton,
  SwiperSlideStyled,
  SwiperStyled,
  WhatsHotContainer,
  WhatsHotSection,
  WhatsHotTitle,
} from "./LandingWhatsHot.styled"
import { LandingWhatsHotSlide } from "./LandingWhatsHotSlide"

const LandingWhatsHot = React.memo((props: ContentfulWhatsHotListProps) => {
  const { list } = props

  const [sectionInView, isSectionInView] = useInView({ threshold: 0.1 })

  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)

  const swiperRef = useRef<SwiperClass>()
  const [activeIndex, setActiveIndex] = useState(0)
  const onIndexChange = (swiper: SwiperClass) => {
    setActiveIndex(swiper.activeIndex)
  }

  const goToIndex = (index: number) => {
    swiperRef.current?.slideTo(index)
  }

  return (
    <WhatsHotSection>
      <WhatsHotTitle variant="h3">What’s Hot?</WhatsHotTitle>
      <WhatsHotContainer ref={sectionInView}>
        <SwiperStyled
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper
          }}
          onActiveIndexChange={onIndexChange}
          modules={[Autoplay, EffectCoverflow, Lazy, Navigation]}
          loop={true}
          effect="coverflow"
          coverflowEffect={{
            rotate: 25,
            scale: 0.8,
            depth: 10,
          }}
          autoplay={{
            delay: 20000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          lazy={{
            enabled: true,
            loadPrevNext: true,
            loadPrevNextAmount: 2,
          }}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
        >
          {list.map((slide, index) => (
            <SwiperSlideStyled key={index}>
              <LandingWhatsHotSlide key={index} {...slide} />
            </SwiperSlideStyled>
          ))}
        </SwiperStyled>
        <NavigationButton direction="prev" ref={navigationPrevRef}>
          <ArrowBackIosNewRoundedIcon />
        </NavigationButton>
        <NavigationButton direction="next" ref={navigationNextRef}>
          <ArrowForwardIosRoundedIcon />
        </NavigationButton>
      </WhatsHotContainer>
    </WhatsHotSection>
  )
})

export { LandingWhatsHot }
