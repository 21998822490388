import { Box, Skeleton, Typography, styled, useTheme } from "decentraland-ui2"

import Video, { VideProps } from "../../Video/Video"

const HeroSection = styled("section")(() => {
  const theme = useTheme()
  return {
    height: "80vh",
    width: "100%",
    padding: 0,
    margin: 0,
    position: "relative",
    minHeight: "600px",
    zIndex: 15,
    [theme.breakpoints.down("xs")]: {
      height: "90vh",
      minHeight: "90vh",
    },
  }
})

const HeroContainer = styled(Box)({
  height: "100%",
})

const Hero = styled(Box)(() => {
  const theme = useTheme()
  return {
    height: "100%",
    width: "100%",
    position: "relative",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    paddingTop: "0",
    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      top: 0,
      bottom: 0,
      right: "50%",
      backgroundImage:
        "linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0))",
      zIndex: 1,
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-end",
      paddingBottom: "70px",
      "&::after": {
        content: '""',
        position: "absolute",
        left: 0,
        top: "50%",
        bottom: 0,
        right: 0,
        backgroundImage:
          "linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0))",
      },
    },
  }
})

const HeroContent = styled(Box)({
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 1,
})

const HeroContentLoading = styled(Skeleton)({
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 0,
  transform: "none",
  transformOrigin: "none",
})

const HeroTextContainer = styled(Box)(() => {
  const theme = useTheme()
  return {
    maxWidth: "610px",
    zIndex: 2,
    marginLeft: "100px",
    marginRight: "100px",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      marginLeft: "20px",
      marginRight: "20px",
    },
  }
})

const HeroTitle = styled(Typography)(() => {
  return {
    fontFamily: "DecentralandHero",
  }
})

const HeroActionsContainer = styled(Box)(() => {
  const theme = useTheme()
  return {
    marginTop: "34px",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  }
})

const HeroImageContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "imageUrl",
})((props: { imageUrl?: string }) => {
  const { imageUrl } = props
  const theme = useTheme()
  return {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "right",
    [theme.breakpoints.down("xs")]: {
      backgroundPosition: "top center",
    },
  }
})

const HeroVideo = styled(Video, {
  shouldForwardProp: (prop) => prop !== "isInView",
})((props: VideProps & { isInView: boolean }) => {
  const { isInView } = props
  return {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 2,
    opacity: isInView ? 1 : 0,
    objectFit: "cover",
    objectPosition: "center",
    transition: "opacity .5s",
    "@media (max-aspect-ratio: 16 / 9)": {
      "&.Video": {
        width: "100%",
        height: "100%",
      },
    },
    "@media (min-aspect-ratio: 16 / 9)": {
      "&.Video": {
        width: "100%",
        height: "100%",
      },
    },
  }
})

export {
  HeroSection,
  HeroContainer,
  Hero,
  HeroContent,
  HeroContentLoading,
  HeroTextContainer,
  HeroTitle,
  HeroActionsContainer,
  HeroImageContainer,
  HeroVideo,
}
