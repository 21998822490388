import React from "react"

import useTrackLinkContext from "decentraland-gatsby/dist/context/Track/useTrackLinkContext"

import {
  isWebpSupported,
  useImageOptimization,
} from "../../../hooks/contentful"
import { SegmentEvent } from "../../../modules/segment"
import { BannerButton } from "../../Buttons/BannerButton"
import {
  WhatsHotSlide,
  WhatsHotSlideActionsContainer,
  WhatsHotSlideContent,
  WhatsHotSlideImageContainer,
  WhatsHotSlideSubtitle,
  WhatsHotSlideTextContainer,
  WhatsHotSlideTitle,
} from "./LandingWhatsHotSlide.styled"
import { LandingWhatsHotSlideProps } from "./LandingWhatsHotSlide.types"

const LandingWhatsHotSlide = React.memo((props: LandingWhatsHotSlideProps) => {
  const { title, subtitle, image, buttonLink, id } = props

  const handleMainCTA = useTrackLinkContext()

  const imageOptimized = useImageOptimization(image.url)

  return (
    <WhatsHotSlide data-index={id}>
      <WhatsHotSlideTextContainer>
        <WhatsHotSlideTitle variant="h4">{title}</WhatsHotSlideTitle>
        <WhatsHotSlideSubtitle variant="h5">
          {subtitle.subtitle}
        </WhatsHotSlideSubtitle>
        <WhatsHotSlideActionsContainer>
          <BannerButton
            href={buttonLink}
            onClick={handleMainCTA}
            label={"Explore"}
            eventData={SegmentEvent.AreYouCreator}
            color="secondary"
            variant="outlined"
          />
        </WhatsHotSlideActionsContainer>
      </WhatsHotSlideTextContainer>
      <WhatsHotSlideContent>
        <WhatsHotSlideImageContainer
          imageUrl={
            (isWebpSupported() && imageOptimized.webp) ||
            imageOptimized.jpg ||
            imageOptimized.optimized
          }
        />
      </WhatsHotSlideContent>
    </WhatsHotSlide>
  )
})

export { LandingWhatsHotSlide }
