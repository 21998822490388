import { MuiMarkdown } from "mui-markdown"

import { Box, Typography, styled, useTheme } from "decentraland-ui2"

const MissionDetailContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isInView",
})((props: { isInView: boolean }) => {
  const { isInView } = props
  const theme = useTheme()
  return {
    marginLeft: "144px",
    marginRight: "144px",
    position: "fixed",
    top: "30%",
    right: isInView ? "50%" : "100%",
    bottom: 0,
    left: isInView ? 0 : "-50%",
    zIndex: 2,
    opacity: isInView ? 1 : 0,
    transition: `${theme.transitions.create(
      ["top", "right", "bottom", "left", "opacity"],
      {
        duration: theme.transitions.duration.shorter,
        easing: theme.transitions.easing.easeInOut,
      }
    )}`,
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      marginLeft: "20px",
      marginRight: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      top: isInView ? "48%" : "100%",
      right: 0,
      bottom: isInView ? "56px" : "-48%",
      left: 0,
      zIndex: 2,
      opacity: isInView ? 1 : 0,
      justifyContent: "space-between",
    },
  }
})

const MissionTextContainer = styled(Box)({})

const MissionActionsContainer = styled(Box)({
  marginTop: "34px",
})

const MissionNumber = styled(Typography)(() => {
  const theme = useTheme()
  return {
    fontSize: "36px",
    fontWeight: 500,
    lineHeight: "48px",
    letterSpacing: "-0.1em",
    textAlign: "left",
    marginRight: "24px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
      marginRight: 0,
    },
  }
})

const MissionTitle = styled(Typography)(() => {
  const theme = useTheme()
  return {
    marginBottom: "32px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      flexDirection: "column",
      marginBottom: "24px",
    },
  }
})

const MissionDescription = styled(MuiMarkdown)({
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "24px",
})

export {
  MissionDetailContainer,
  MissionTextContainer,
  MissionActionsContainer,
  MissionNumber,
  MissionTitle,
  MissionDescription,
}
