import { Box, styled, useTheme } from "decentraland-ui2"

const Mission = styled(Box)(() => {
  const theme = useTheme()
  return {
    height: "100vh",
    width: "100%",
    position: "relative",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    paddingTop: "0",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-end",
      paddingBottom: "70px",
    },
  }
})

export { Mission }
