import React from "react"

import { BannerButtonStyled } from "./BannerButton.styled"
import { BannerButtonProps } from "./BannerButton.types"

const BannerButton = React.memo((props: BannerButtonProps) => {
  const {
    href,
    onClick,
    label,
    eventData,
    variant = "contained",
    color = "primary",
  } = props
  return (
    <BannerButtonStyled
      variant={variant}
      color={color}
      data-event={eventData}
      data-place="hero"
      href={href}
      onClick={onClick}
    >
      {label}
    </BannerButtonStyled>
  )
})

export { BannerButton }
