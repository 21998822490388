import React from "react"

import { Mobile, NotMobile } from "decentraland-ui/dist/components/Media/Media"

import { useVideoOptimization } from "../../../hooks/contentful"
import { LandingMissionsV2Props } from "./LandingMissionsV2.types"
import { MissionContent, MissionVideo } from "./LandingMissionV2Video.styled"

const LandingMissionV2Video = React.memo(
  (props: LandingMissionsV2Props & { isSectionInView: boolean }) => {
    const { videoLandscape, videoPortrait, isSectionInView } = props

    const videoLandscapeOptimized = useVideoOptimization(videoLandscape?.url)
    const videoPortraitOptimized = useVideoOptimization(videoPortrait?.url)

    return (
      <MissionContent>
        <NotMobile>
          {videoLandscape && (
            <MissionVideo
              loop
              muted
              autoPlay
              playsInline={true}
              width={videoLandscape.width}
              height={videoLandscape.height}
              isInView={isSectionInView}
              source={videoLandscapeOptimized || videoLandscape.url}
              type={videoLandscape.mimeType}
            />
          )}
        </NotMobile>
        <Mobile>
          {videoPortrait && (
            <MissionVideo
              loop
              muted
              autoPlay
              playsInline={true}
              width={videoPortrait.width}
              height={videoPortrait.height}
              isInView={isSectionInView}
              source={videoPortraitOptimized || videoPortrait.url}
              type={videoPortrait.mimeType}
            />
          )}
        </Mobile>
      </MissionContent>
    )
  }
)

export { LandingMissionV2Video }
