import React, { useEffect } from "react"

import { useInView } from "react-intersection-observer"

import { LandingMissionV2ViewProps } from "./LandingMissionsV2.types"
import { Mission } from "./LandingMissionV2View.styled"

const LandingMissionV2View = React.memo((props: LandingMissionV2ViewProps) => {
  const { id, onIsInView } = props

  const [sectionInView, sectionIsInView] = useInView({
    threshold: 0.5,
  })

  useEffect(() => {
    if (sectionIsInView) {
      onIsInView()
    }
  }, [sectionIsInView])

  return <Mission ref={sectionInView} data-index={id}></Mission>
})

export { LandingMissionV2View }
