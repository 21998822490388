import React from "react"

import useTrackLinkContext from "decentraland-gatsby/dist/context/Track/useTrackLinkContext"

import { BannerButton } from "../../Buttons/BannerButton"
import { LandingMissionsV2DetailProps } from "./LandingMissionsV2.types"
import {
  MissionActionsContainer,
  MissionDescription,
  MissionDetailContainer,
  MissionNumber,
  MissionTextContainer,
  MissionTitle,
} from "./LandingMissionV2Detail.styled"

const LandingMissionV2Detail = React.memo(
  (props: LandingMissionsV2DetailProps) => {
    const {
      title,
      description,
      buttonLabel,
      buttonLink,
      buttonType,
      eventData,
      isSectionInView,
      index,
    } = props

    const handleMainCTA = useTrackLinkContext()

    return (
      <MissionDetailContainer isInView={isSectionInView}>
        <MissionTextContainer>
          <MissionTitle variant="h3">
            <MissionNumber>{index}</MissionNumber>
            {title}
          </MissionTitle>
          <MissionDescription>{description.description}</MissionDescription>
        </MissionTextContainer>
        <MissionActionsContainer>
          <BannerButton
            variant={buttonType === "primary" ? "contained" : "outlined"}
            color={buttonType}
            href={buttonLink}
            onClick={handleMainCTA}
            label={buttonLabel}
            eventData={eventData}
          />
        </MissionActionsContainer>
      </MissionDetailContainer>
    )
  }
)

export { LandingMissionV2Detail }
