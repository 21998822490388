import { Box, Typography, styled, useTheme } from "decentraland-ui2"

const WhatsHotSlide = styled(Box)({
  position: "relative",
  height: "625px",
  maxWidth: "755px",
  width: "80vw",
  borderRadius: "20px",
  overflow: "hidden",
  "::after": {
    content: "''",
    position: "absolute",
    top: "50%",
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%)`,
  },
})

const WhatsHotSlideContent = styled(Box)({})

const WhatsHotSlideTextContainer = styled(Box)(() => {
  const theme = useTheme()
  return {
    zIndex: 2,
    marginLeft: "100px",
    marginRight: "100px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    height: "calc(100% - 77px)",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      marginRight: "20px",
    },
  }
})

const WhatsHotSlideTitle = styled(Typography)({})

const WhatsHotSlideSubtitle = styled(Typography)(() => {
  const theme = useTheme()

  return {
    color: theme.palette.text.secondary,
  }
})

const WhatsHotSlideActionsContainer = styled(Box)({
  marginTop: "34px",
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
})

const WhatsHotSlideImageContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "imageUrl",
})((props: { imageUrl?: string }) => {
  const { imageUrl } = props
  return {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  }
})

export {
  WhatsHotSlide,
  WhatsHotSlideContent,
  WhatsHotSlideTextContainer,
  WhatsHotSlideTitle,
  WhatsHotSlideSubtitle,
  WhatsHotSlideActionsContainer,
  WhatsHotSlideImageContainer,
}
