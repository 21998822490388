import React from "react"

import { useInView } from "react-intersection-observer"

import useTrackLinkContext from "decentraland-gatsby/dist/context/Track/useTrackLinkContext"
import {
  Desktop,
  Mobile,
  NotMobile,
} from "decentraland-ui/dist/components/Media/Media"
import { useMobileMediaQuery } from "decentraland-ui2/dist/components/Media"

import { Typography } from "decentraland-ui2"

import {
  isWebpSupported,
  useImageOptimization,
  useVideoOptimization,
} from "../../../hooks/contentful"
import useDesktopTarget from "../../../hooks/useDesktopTarget"
import { EnterMetaverseButton } from "../../Buttons/EnterMetaverseButton"
import {
  Hero,
  HeroActionsContainer,
  HeroContainer,
  HeroContent,
  HeroContentLoading,
  HeroImageContainer,
  HeroSection,
  HeroTextContainer,
  HeroTitle,
  HeroVideo,
} from "./LandingHeroV2.styled"
import { LandingHeroV2Props } from "./LandingHeroV2.types"

const LandingHeroV2 = React.memo((props: { hero: LandingHeroV2Props }) => {
  const [desktopTarget, loadingDesktopTarget] = useDesktopTarget()

  const {
    title,
    subtitle,
    imageLandscape,
    videoLandscape,
    imagePortrait,
    videoPortrait,
    buttonPrimaryLabel,
    id,
  } = props.hero

  const handleMainCTA = useTrackLinkContext()

  const [sectionInView, isSectionInView] = useInView({ threshold: 0.1 })

  const isMobile = useMobileMediaQuery()

  const imageLandscapeOptimized = useImageOptimization(imageLandscape.url)
  const imagePortraitOptimized = useImageOptimization(imagePortrait.url)
  const videoLandscapeOptimized = useVideoOptimization(videoLandscape?.url)
  const videoPortraitOptimized = useVideoOptimization(videoPortrait?.url)

  return (
    <HeroSection>
      <HeroContainer ref={sectionInView}>
        <Hero data-index={id}>
          <HeroTextContainer>
            <HeroTitle variant={isMobile ? "h2" : "h1"}>{title}</HeroTitle>
            <Typography variant="h6">{subtitle.text}</Typography>
            <HeroActionsContainer>
              <Desktop>
                {!loadingDesktopTarget && !!desktopTarget && (
                  <EnterMetaverseButton
                    href={desktopTarget}
                    onClick={handleMainCTA}
                    label={buttonPrimaryLabel!}
                  />
                )}
              </Desktop>
            </HeroActionsContainer>
          </HeroTextContainer>
          <HeroContent>
            <HeroContentLoading />
            <NotMobile>
              {videoLandscape && (
                <HeroVideo
                  loop
                  muted
                  autoPlay
                  playsInline={true}
                  width={videoLandscape.width}
                  height={videoLandscape.height}
                  poster={
                    (isWebpSupported() && imageLandscapeOptimized.webp) ||
                    imageLandscapeOptimized.jpg ||
                    imageLandscapeOptimized.optimized
                  }
                  isInView={isSectionInView}
                  source={videoLandscapeOptimized || videoLandscape.url}
                  type={videoLandscape.mimeType}
                />
              )}
              {imageLandscape && (
                <HeroImageContainer
                  imageUrl={
                    (isWebpSupported() && imageLandscapeOptimized.webp) ||
                    imageLandscapeOptimized.jpg ||
                    imageLandscapeOptimized.optimized
                  }
                />
              )}
            </NotMobile>
            <Mobile>
              {videoPortrait && (
                <HeroVideo
                  loop
                  muted
                  autoPlay
                  playsInline={true}
                  width={videoPortrait.width}
                  height={videoPortrait.height}
                  poster={
                    (isWebpSupported() && imagePortraitOptimized.webp) ||
                    imagePortraitOptimized.jpg ||
                    imagePortraitOptimized.optimized
                  }
                  isInView={isSectionInView}
                  source={videoPortraitOptimized || videoPortrait.url}
                  type={videoPortrait.mimeType}
                />
              )}
              {imagePortrait && (
                <HeroImageContainer
                  imageUrl={
                    (isWebpSupported() && imagePortraitOptimized.webp) ||
                    imagePortraitOptimized.jpg ||
                    imagePortraitOptimized.optimized
                  }
                />
              )}
            </Mobile>
          </HeroContent>
        </Hero>
      </HeroContainer>
    </HeroSection>
  )
})

export { LandingHeroV2 }
