import { Button, styled, useTheme } from "decentraland-ui2"

const ExploreButton = styled(Button)(() => {
  const theme = useTheme()
  return {
    "&.MuiButton-sizeMedium.MuiButton-containedPrimary": {
      padding: "20px 50px",
      fontSize: "16px",
      fontWeight: 700,
      borderRadius: "8px",
    },
    "&.MuiButton-sizeMedium.MuiButton-containedPrimary:not(.Mui-disabled):not(.Mui-focusVisible):hover":
      {
        backgroundColor: theme.palette.primary.main,
      },
    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      border: `4px solid #FCFCFC`,
      borderRadius: "14px",
      opacity: 0,
      transition: `${theme.transitions.create(
        ["top", "right", "bottom", "left", "opacity"],
        {
          duration: theme.transitions.duration.shorter,
          easing: theme.transitions.easing.easeInOut,
        }
      )}`,
    },
    "&:hover": {
      "&::before": {
        opacity: 1,
        top: "-7.5px",
        right: "-7.5px",
        bottom: "-7.5px",
        left: "-7.5px",
      },
    },
  }
})

export { ExploreButton }
